<template>
  <div>
    <div class="level mt-2">
      <div class="level-left">
        <div class="level-item">
          <h1 style="font-size: 1.5em; font-weight: bold">
            {{ token.first_name }} {{ token.last_name }}
          </h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item"></div>
      </div>
    </div>
    <b-tabs>
      <b-tab-item label="Allgemeine Angaben">
        <div class="columns">
          <div class="column is-6">
            <b-field label="Vorname">
              <b-input
                v-model="token.first_name"
                required
                :disabled="true"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field label="Nachname">
              <b-input
                v-model="token.last_name"
                required
                :disabled="true"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <b-field label="Zertifikatsart">
              <b-select v-model="token.covid_status" expanded :disabled="true">
                <option
                  v-for="status in $store.getters.covidStatuses"
                  :value="status.id"
                  :key="status.id"
                >
                  {{ status.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field label="Gültig bis">
              <b-datetimepicker
                v-model="token.status_valid_until"
                icon="calendar-today"
                locale="de-DE"
                :disabled="true"
              >
              </b-datetimepicker>
            </b-field>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Alle Scans">
        <b-table
          :data="scans"
          :total="totalScans"
          paginated
          default-sort="created"
          default-sort-direction="desc"
        >
          <b-table-column
            field="scanner"
            label="Scanner ID"
            v-slot="props"
          >
            {{ props.row.scanner.name }}
          </b-table-column>
           <b-table-column
            field="covid_status"
            label="Zertifizierungsart"
            v-slot="props"
            class="has-text-center"
          >
            <b-tag
              icon-pack="fas"
              icon="syringe"
              v-if="props.row.covid_status == 1"
              type="is-success"
              >Geimpft</b-tag
            >
            <b-tag
              icon-pack="fas"
              icon="diagnoses"
              v-if="props.row.covid_status == 2"
              type="is-success"
              >Genesen</b-tag
            >
            <b-tag
              icon-pack="fas"
              icon="syringe"
              v-if="props.row.covid_status == 3"
              type="is-warning"
              >Getestet</b-tag
            >
          </b-table-column>
          <b-table-column
            field="created"
            label="Gescannt am"
            sortable
            v-slot="props"
          >
            {{ formatDateTime(props.row.created) }} Uhr
          </b-table-column>
          <template slot="bottom-left"> Gesamt: {{ totalScans }} </template>
        </b-table>
      </b-tab-item>
      <b-tab-item label="Systemfelder">
        <div class="columns">
          <div class="column is-3">
            <b-field label="ID">
              <b-input v-model="token.id" required :disabled="true"></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="RFID Token">
              <b-input
                v-model="token.rfid_token"
                required
                :disabled="true"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">
            <b-field label="Erstellt am">
              <b-datetimepicker
                v-model="token.created"
                icon="calendar-today"
                locale="de-DE"
                :disabled="true"
              >
              </b-datetimepicker>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Erstellt von">
              <b-input
                v-model="token.create_user"
                required
                :disabled="true"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Aktualisiert am">
              <b-datetimepicker
                v-model="token.updated"
                icon="calendar-today"
                locale="de-DE"
                :disabled="true"
              >
              </b-datetimepicker>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Aktualisiert von">
              <b-input
                v-model="token.update_user"
                required
                :disabled="true"
              ></b-input>
            </b-field>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { dateMixin } from "../mixins/DateMixin";

export default {
  name: "Home",
  mixins: [dateMixin],
  components: {},
  data() {
    return {
      rfid_token: this.$route.params.id,
      token: null,
      isLoading: true,
      scans: [],
      totalScans: 0,
    };
  },
  methods: {
    init() {
      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/employee/" + this.rfid_token)
        .then((response) => {
          this.token = response.data.data;

          this.token.created = this.getInLocalTimezone(
            this.token.created
          ).toDate();
          this.token.updated = this.getInLocalTimezone(
            this.token.updated
          ).toDate();
          this.token.status_valid_until = this.getInLocalTimezone(
            this.token.status_valid_until
          ).toDate();

          this.loadScans();
        });
    },
    loadScans() {
      this.isLoading = true;
      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/scans/" + this.token.id)
        .then((response) => {
          console.log(response);

          this.scans = response.data;
          this.totalScans = this.scans.length;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.tabs li.is-active a {
  border-color: #c20000;
  color: #c20000;
}
</style>
