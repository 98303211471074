import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'
import Buefy from 'buefy'
import { store } from "./store/store.js";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Certificate from "./components/Certificate.vue";
import NewToken from "./components/NewToken.vue";
import Reporting from "./components/Reporting.vue";
import Employees from "./components/Employees.vue";
import Scans from "./components/Scans.vue";
import EmployeeDetail from "./components/EmployeeDetail.vue";
import 'buefy/dist/buefy.css'
import JsonCSV from 'vue-json-csv'

Vue.component('downloadCsv', JsonCSV)

Vue.use(Buefy)

Vue.use(Vuex)
Vue.use(VueRouter)

Vue.config.productionTip = false

export const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/home",
      name: "home",
      component: Home,
    },
    {
      path: "/new",
      name: "newtoken",
      component: NewToken,
    },    
    {
      path: "/login",
      name: "login",
      component: Login,
    },  
    {
      path: "/certificate",
      name: "certificate",
      component: Certificate,
    }, 
    {
      path: "/reporting",
      name: "reporting",
      component: Reporting,
    }, 
    {
      path: "/employees/:id",
      name: "employeedetail",
      component: EmployeeDetail,
    },
    {
      path: "/employees",
      name: "employees",
      component: Employees,
    },  
    {
      path: "/scans",
      name: "scans",
      component: Scans,
    },      
    {
      path: "*",
      component: Home,
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
