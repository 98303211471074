<template>
  <bar-chart :chart-data="datacollection" :options="chartOptions"></bar-chart>
</template>

<script>
import BarChart from "./BarChart.js";

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      datacollection: null,
      total_vaccinated: 0,
      total_recovered: 0,
      total_tested: 0,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                format: "YYYY-MM-DD",
                tooltipFormat: "ll",
              },
              scaleLabel: {
                display: true,
                labelString: "Datum und Uhrzeit",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 1,
                stepValue: 1,
              },
            },
          ],
        },
      },
    };
  },
  props: {
    scans: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {

      let data = []

      this.scans.forEach((scan) => {
        data.push({
          x: scan.date,
          y: scan.distinct_amount
        })
      });

      console.log(data);

      this.datacollection = {
        datasets: [
          {
            label: "Anzahl gescannte Token",
            backgroundColor: "#c20000",
            data: data,
          },
        ],
      };
    },
  },
  watch: {
    scans() {
      this.fillData();
    },
  },
};
</script>
<style></style>
