<template>
  <div>
    <h1 class="is-size-2">Willkommen!</h1>
    <router-link
      tag="a"
      :to="{ path: '/new' }"
      class="button is-success mt-3"
      v-if="$store.getters.user != null"
    >
      Neuen Token registrieren
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  methods: {},
};
</script>

<style></style>
