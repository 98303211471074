<template>
  <div>
    <section>
      <div class="columns">
        <div class="column is-3">
          <tile
            :value="Number(total_employees).toLocaleString()"
            title="Erfasste Zertifikate"
            icon="users"
          />
        </div>
        <div class="column is-3">
          <tile
            :value="Number(total_vaccinated_employees).toLocaleString()"
            title="Geimpfte Personen"
            icon="syringe"
          />
        </div>
        <div class="column is-3">
          <tile
            :value="Number(total_recovered_employees).toLocaleString()"
            title="Genesene Personen"
            icon="diagnoses"
          />
        </div>
        <div class="column is-3">
          <tile
            :value="Number(total_scans).toLocaleString()"
            title="Gescannte Tokens"
            icon="key"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-3">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">Verteilung Zertifikatsarten</p>
            </header>
            <div class="card-content">
              <covid-status-chart :statuses="statuses" />
            </div>
          </div>
        </div>
        <div class="column is-9">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                Gescannte Token<span class="ml-1">
                  <span
                    v-if="this.date_from && this.date_until"                    
                    >({{ this.date_from }} bis {{ this.date_until }})</span
                  >
                  <span
                    v-if="this.date_from && !this.date_until"                    
                    >(seit {{ this.date_from }})</span
                  >
                  <span
                    v-if="!this.date_from && this.date_until"
                    >(bis {{ this.date_until }})</span
                  >
                  <span
                    v-if="!this.date_from && !this.date_until"                    
                    >(kompletter Zeitraum)</span
                  >
                </span>
              </p>
            </header>
            <div class="card-content">
              <scan-chart :scans="scans" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CovidStatusChart from "./charts/CovidStatusChart.vue";
import ScanChart from "./charts/ScanChart.vue";
import Tile from "./Tile.vue";
export default {
  name: "Reporting",
  components: { Tile, CovidStatusChart, ScanChart },
  data() {
    return {
      total_employees: 0,
      total_scans: 0,
      total_vaccinated_employees: 0,
      total_recovered_employees: 0,
      statuses: [],
      scans: [],
      date_from: null,
      date_until: null,
    };
  },
  methods: {
    init() {
      this.loadReport();
    },
    loadReport() {
      const payload = {
        date_from: this.date_from,
        date_until: this.date_until,
      };

      this.$store.getters.axios
        .put(this.$store.getters.apiUrl + "/report", payload)
        .then((response) => {
          let data = response.data;
          this.total_employees = data.total_employees;
          this.total_scans = data.total_scans;

          this.statuses = data.statuses;
          this.scans = data.scans;

          this.total_vaccinated_employees = data.statuses.find(
            (x) => x.id === 1
          ).amount;
          this.total_recovered_employees = data.statuses.find(
            (x) => x.id === 2
          ).amount;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
