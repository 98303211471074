const ROLE_ADMIN = "ADMIN"
const ROLE_GUEST = "GUEST"

export const userService = {
  login,
  logout,
  ROLE_ADMIN: ROLE_ADMIN,
  ROLE_GUEST: ROLE_GUEST
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(process.env.VUE_APP_BACKEND_URL + '/login', requestOptions)
    .then(handleResponse)
    .then(async (user) => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user))
      }
      return user
    })
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
