<template>
 <b-navbar type="is-black" wrapper-class="container">
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img
                    src="https://portal.trilux.com/templatev2/images/base/trilux_logo.svg"
                >
            </b-navbar-item>
        </template>
        <template #start>
            <router-link tag="b-navbar-item" :to="{ path: '/reporting'}" v-if="$store.getters.isAdmin">
                Reporting
            </router-link>
            <router-link tag="b-navbar-item" :to="{ path: '/employees'}" v-if="$store.getters.isAdmin">
                Mitarbeiter
            </router-link>
            <router-link tag="b-navbar-item" :to="{ path: '/scans'}" v-if="$store.getters.isAdmin">
                Scans
            </router-link>
        </template>
        <template #end>
            <b-navbar-item tag="div">
                <div class="buttons">
                    <router-link tag="a" :to="{ path: '/new'}" class="button is-success" v-if="$store.getters.user != null">
                        Neuer Token
                    </router-link>
                    <router-link :to="{ path: '/login' }" tag="a" class="button is-light" v-if="$store.getters.user != null">
                        Abmelden
                    </router-link>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>

</style>
