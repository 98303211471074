<template>
  <div>
    <div class="level mt-2">
      <div class="level-left">
        <div class="level-item">
          <h1 style="font-size: 1.5em; font-weight: bold">Scans</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <download-csv :data="csvData" delimiter=";"
            ><b-button
              type="is-light"
              icon-pack="fas"
              icon-left="download"
              :disabled="isLoading"
              >Download</b-button
            ></download-csv
          >
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Datum auswählen">
          <b-datepicker
            v-model="date"
            :first-day-of-week="1"
            placeholder="Bitte wähle ein Datum..."
            :disabled="isLoading"
            @input="loadScans()"
            locale="de-DE"
            :editable="false"
          >
            <b-button
              label="Today"
              type="is-primary"
              icon-left="calendar-today"
              @click="date = new Date(); loadScans()"
            />
          </b-datepicker>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <b-table
          :data="data"
          :per-page="perPage"
          :current-page="page"
          :loading="isLoading"
          :total="totalScans"
          paginated
          default-sort-direction="desc"
          default-sort="created"
        >
          <b-table-column
            field="first_name"
            label="Vorname"
            sortable
            v-slot="props"
          >
            {{ props.row.employee_status.first_name }}
          </b-table-column>
          <b-table-column
            field="last_name"
            label="Nachname"
            sortable
            v-slot="props"
          >
            {{ props.row.employee_status.last_name }}
          </b-table-column>
          <b-table-column
            field="covid_status"
            label="Zertifizierungsart"
            v-slot="props"
            class="has-text-center"
          >
            <b-tag
              icon-pack="fas"
              icon="syringe"
              v-if="props.row.covid_status == 1"
              type="is-success"
              >Geimpft</b-tag
            >
            <b-tag
              icon-pack="fas"
              icon="diagnoses"
              v-if="props.row.covid_status == 2"
              type="is-success"
              >Genesen</b-tag
            >
            <b-tag
              icon-pack="fas"
              icon="syringe"
              v-if="props.row.covid_status == 3"
              type="is-warning"
              >Getestet</b-tag
            >
          </b-table-column>
          <b-table-column field="scanner" label="Scanner" v-slot="props">
            {{ props.row.scanner.name }}
          </b-table-column>
          <b-table-column
            field="created"
            label="Gescannt am"
            sortable
            v-slot="props"
          >
            {{ formatDateTime(props.row.created) }} Uhr
          </b-table-column>
          <b-table-column field="favorite" label="" numeric v-slot="props">
            <b-button
              v-if="props.row.employee_status.rfid_token != null"
              size="is-small"
              @click="showEmployeeDetails(props.row.employee_status.rfid_token)"
              ><b-icon pack="fas" icon="eye"
            /></b-button>
          </b-table-column>
          <template slot="bottom-left"> Gesamt: {{ totalScans }} </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { dateMixin } from "../mixins/DateMixin.js";

export default {
  name: "Scans",
  components: {},
  mixins: [dateMixin],
  data() {
    return {
      data: [],
      csvData: [],
      totalScans: 0,
      page: 1,
      perPage: 10,
      isLoading: true,
      date: new Date(),
    };
  },
  methods: {
    init() {
      this.loadScans();
    },
    changePage(page) {
      this.page = page;
      this.loadEmployees();
    },
    showEmployeeDetails(id) {
      console.log("details", id);
      this.$router.push({ name: "employeedetail", params: { id: id } });
    },
    loadScans() {
      this.isLoading = true;

      const params = {
        date: this.date,
      };

      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/scans/", { params: params })
        .then((response) => {
          this.data = response.data;
          this.totalScans = this.data.length;

          this.csvData = [];

          this.data.forEach((item) => {
            let covid_status = "unbekannt";

            switch (item["covid_status"]) {
              case 1:
                covid_status = "geimpft";
                break;
              case 2:
                covid_status = "genesen";
                break;
              case 3:
                covid_status = "getestet";
                break;
            }

            this.csvData.push({
              Vorname: item["employee_status"]["first_name"],
              Nachname: item["employee_status"]["last_name"],
              Zertifizierungsart: covid_status,
              Scanner: item["scanner"]["name"],
              "Gescannt am": this.formatDateTime(item["created"]),
            });
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.pagination-link.is-current {
  background-color: #c20000;
  border-color: #c20000;
  color: #fff;
}
</style>
