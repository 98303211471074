<template>
  <div id="app">
    <base-header />
    <div class="container pt-4 px-5">
      <router-view />
    </div>
  </div>
</template>

<script>
import BaseHeader from "./BaseHeader.vue";
export default {
  name: "App",
  components: {
    BaseHeader,
  },
};
</script>
