import moment from 'moment'

export const dateMixin = {
  methods: {
    formatDate: function (date) {
      if (date === '-') {
        return date
      }
      return moment(date)
        .locale('de')
        .format('L')
    },
    formatDateTime: function (date) {
      if (date == null) {
        return '-'
      }
      return moment(date)
        .locale('de')
        .format('L LT')
    },
    getInLocalTimezone: function (date) {
      return moment(date).local()
    }
  }
}
