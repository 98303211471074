<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-mobile">
        <div class="column is-4 is-align-self-center">
          <b-icon
            pack="fas"
            :icon="icon"
            size="is-medium"
            class="tile-icon ml-4"
          />
        </div>
        <div class="column is-8">
          <span class="tile-title">{{ title }}</span><br />
          <span class="tile-value">{{ value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tile",
  components: {},
  props: {
    value: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
.tile-value {
  font-weight: bold;
  font-size: 1.5rem;
}

.icon.tile-icon.is-medium {
  font-size: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
}
</style>
