<template>
  <bar-chart :chart-data="datacollection" :options="chartOptions"></bar-chart>
</template>

<script>
import BarChart from "./BarChart.js";

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      datacollection: null,
      total_vaccinated: 0,
      total_recovered: 0,
      total_tested: 0,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      },
    };
  },
  props: {
    statuses: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      if (this.statuses.find((x) => x.id === 1))
        this.total_vaccinated = this.statuses.find((x) => x.id === 1).amount;

      if (this.statuses.find((x) => x.id === 2))
        this.total_recovered = this.statuses.find((x) => x.id === 2).amount;

      if (this.statuses.find((x) => x.id === 3))
        this.total_tested = this.statuses.find((x) => x.id === 3).amount;

      this.datacollection = {
        labels: ["Geimpft", "Genesen", "Getestet"],
        datasets: [
          {
            label: "Anzahl erfasste Zertifikate",
            backgroundColor: "#c20000",
            data: [
              this.total_vaccinated,
              this.total_recovered,
              this.total_tested,
            ],
          },
        ],
      };
    },
  },
  watch: {
    statuses() {
      this.fillData();
    },
  },
};
</script>
<style></style>
