<template>
  <div class="columns">
    <div class="column">
      <qrcode-stream @decode="onDecode">
        <div v-show="showScanConfirmation" class="scan-confirmation">          
          <b-icon icon="tick-circle-outline" size="is-large" />
        </div>
      </qrcode-stream>
    </div>
    <div class="column">
      <p>Bitte scanne einen QR-Code um einen Token zu verknüpfen.</p>
      <div class="mt-3">
        <p v-if="tokenId != null"><strong>Token Id:</strong> {{ tokenId }}</p>
        <p v-if="employeeStatus != null"><strong>Status:</strong> {{ employeeStatus.message }}</p>

        <router-link tag="a" :to="{ path: '/certificate' }" v-if="employeeStatus != null && employeeStatus.status == 'NOT_USED'" class="button is-light mt-3">Zertifikat verknüpfen</router-link>
        <router-link tag="a" :to="{ path: '/certificate' }" v-if="employeeStatus != null && employeeStatus.status == 'ALREADY_USED'" class="button is-light mt-3">Neues Zertifikat verknüpfen</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "NewToken",
  components: {
    QrcodeStream,
  },
  data() {
    return {
      tokenId: null,
      showScanConfirmation: false,
      camera: "auto",
      employeeStatus: null
    };
  },
  methods: {
    onDecode(decodedString) {
      this.showScanConfirmation = true;

      if(decodedString.startsWith("HC1:")){
        this.showScanConfirmation = false;
        return;
      }

      if (decodedString.includes("/")) {
        this.tokenId =
          decodedString.split("/")[decodedString.split("/").length - 1];
      }

      console.log(this.tokenId);

      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/employee/" + this.tokenId)
        .then((response) => {

          if(response.data.status == 'NOT_USED'){
            console.log('not used')
            this.$store.state.tokenId = this.tokenId;
          } else if(response.data.status == 'ALREADY_USED'){
            console.log('already used')
            this.$store.state.tokenId = this.tokenId;
          }

          this.employeeStatus = response.data;
        });
    },
    scanAgain(){
      this.showScanConfirmation = false;
      this.camera = "off";
      this.camera = "auto";
    }
  },
};
</script>

<style scoped>
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
</style>
