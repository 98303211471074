<template>
  <section>
    <b-message type="is-danger" v-if="error">
      Benutzername oder Passwort falsch.
    </b-message>
    <b-field label="Benutzer">
      <b-input v-model="username"></b-input>
    </b-field>
    <b-field label="Passwort">
      <b-input type="password" v-model="password" password-reveal> </b-input>
    </b-field>
    <b-button @click="submitLogin" :loading="loggingIn">Anmelden</b-button>
  </section>
</template>

<script>
export default {
  name: "Login",
  components: {},
  methods: {
    submitLogin() {
      const { username, password } = this;
      const { dispatch } = this.$store;
      if (username && password) {
        dispatch("authentication/login", { username, password });
      }
    },
  },
  data() {
    return {
      username: null,
      password: null
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
    loggedout() {
      return this.$store.state.authentication.status.loggedout;
    },
    error() {
      return this.$store.state.authentication.status.error;
    },
  },
  created() {
    // reset login status
    this.$store.dispatch("authentication/logout");
  },
};
</script>

<style></style>
