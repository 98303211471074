<template>
  <div class="columns">
    <div class="column" v-if="certificate == null">
      <qrcode-stream @decode="onDecode">
        <div v-show="showScanConfirmation" class="scan-confirmation">
          <b-icon icon="tick-circle-outline" size="is-large" />
        </div>
      </qrcode-stream>
    </div>
    <div class="column">
      <div v-if="certificate == null">
        <p>Bitte scanne das Zertifikat des Mitarbeiters.</p>
        <p class="mt-1">
          Sollte der Mitarbeiter kein digitales Zertifikat haben, musst du die Daten manuell erfassen:
        </p>
        <b-field label="Vorname">
          <b-input v-model="firstName"></b-input>
        </b-field>
        <b-field label="Nachname">
          <b-input v-model="lastName"></b-input>
        </b-field>
        <b-field label="3G-Status">
          <b-select v-model="certificateType" expanded>
            <option
              v-for="option in data"
              :value="option.key"
              :key="option.key"
            >
              {{ option.value }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Gültig bis / Ausgestellt am">
          <b-datepicker v-model="validUntil"> </b-datepicker>
        </b-field>
        <b-button
            type="is-light"
            @click="saveCertificate"
            class="mt-2"
            :loading="isLoading"
            >Verknüpfung abschließen</b-button
          >
      </div>
      <div
        class="columns is-mobile mt-3"
        v-if="certificate && certificate.is_valid"
      >
        <div class="column">
          <img src="@/assets/check-circle-regular.svg" class="p-6" />
        </div>
        <div class="column">
          <p class="is-size-4">Zertifikat gültig!</p>
          <p>
            Gleiche jetzt die folgenden Daten mit dem Ausweisdokument der
            geprüften Person ab.
          </p>
          <table style="width: 100%" class="mt-2">
            <tr>
              <td>Vorname</td>
              <td>{{ certificate.first_name }}</td>
            </tr>
            <tr>
              <td>Nachname</td>
              <td>{{ certificate.last_name }}</td>
            </tr>
            <tr
              v-if="
                certificate.certificate_type == 'T' ||
                certificate.certificate_type == 'G'
              "
            >
              <td>Gültig bis</td>
              <td>{{ certificate.valid_until }}</td>
            </tr>
          </table>
          <b-field class="mt-3" v-if="certificate.certificate_type == 'V'">
            <b-checkbox v-model="checkbox">
              Der Mitarbeiter willigt ein, dass auch das Datum der letzten
              Impfung gespeichert werden darf. So wäre eine neue Registrierung
              nicht unbedingt notwendig, wenn die Impfung abläuft.
            </b-checkbox>
          </b-field>
          <b-button
            type="is-light"
            @click="saveCertificate"
            class="mt-2"
            :loading="isLoading"
            >Verknüpfung abschließen</b-button
          >
        </div>
      </div>
      <div
        class="columns is-mobile mt-3"
        v-if="certificate && !certificate.is_valid"
      >
        <div class="column">
          <img src="@/assets/times-circle-regular.svg" class="p-6" />
        </div>
        <div class="column">
          <p class="is-size-4">Zertifikat ungültig!</p>
          <p>Dieses Zertifikat ist nicht gültig.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "Certificate",
  components: {
    QrcodeStream,
  },
  data() {
    return {
      showScanConfirmation: false,
      camera: "auto",
      certificate: null,
      isLoading: false,
      data: [
        {
          key: "V",
          value: "geimpft",
        },
        {
          key: "T",
          value: "getestet",
        },
        {
          key: "R",
          value: "genesen",
        },
      ],
      firstName: null,
      lastName: null,
      certificateType: "V",
      validUntil: null,
    };
  },
  methods: {
    init() {
      if (this.$store.getters.tokenId == null) {
        this.$router.push("/new");
        console.log("tokenId is null");
      }
    },
    onDecode(decodedString) {
      this.showScanConfirmation = true;

      if (!decodedString.startsWith("HC1:")) {
        this.showScanConfirmation = false;
        return;
      }

      const request = {
        qr_content: decodedString,
      };

      this.$store.getters.axios
        .post(this.$store.getters.apiUrl + "/certificate", request)
        .then((response) => {
          console.log(response);
          this.certificate = response.data;
        });
    },
    scanAgain() {
      this.showScanConfirmation = false;
      this.camera = "off";
      this.camera = "auto";
    },
    saveCertificate() {
      this.isLoading = true;

      let request = {
        token_id: this.$store.getters.tokenId
      }

      if(this.certificate != null){
        request['first_name'] = this.certificate.first_name,
        request['last_name'] = this.certificate.last_name,
        request['certificate_type'] = this.certificate.certificate_type,
        request['valid_until'] = this.certificate.valid_until
      } else {
        request['first_name'] = this.firstName,
        request['last_name'] = this.lastName,
        request['certificate_type'] = this.certificateType,
        request['valid_until'] = this.validUntil
      }

      this.$store.getters.axios
        .post(this.$store.getters.apiUrl + "/link", request)
        .then((response) => {
          console.log(response);

          this.$store.state.tokenId = null;

          this.$buefy.toast.open({
            message: "Verknüpfung gespeichert.",
            type: "is-success",
          });

          this.$router.push("/");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
</style>
