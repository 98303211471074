<template>
  <div>
    <div class="level mt-2">
      <div class="level-left">
        <div class="level-item">
          <h1 style="font-size: 1.5em; font-weight: bold">
            Mitarbeiter
          </h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item"></div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-9">
        <b-table
          :data="data"
          :per-page="perPage"
          :current-page="page"
          :loading="isLoading"
          :total="totalEmployees"
          paginated
          backend-pagination
          backend-sorting
          @page-change="changePage"
          @sort="sortEmployees"
        >
          <b-table-column
            field="first_name"
            label="Vorname"
            sortable
            v-slot="props"
          >
            {{ props.row.first_name }}
          </b-table-column>
          <b-table-column
            field="last_name"
            label="Nachname"
            sortable
            v-slot="props"
          >
            {{ props.row.last_name }}
          </b-table-column>
          <b-table-column
            field="covid_status"
            label="Zertifizierungsart"
            sortable
            v-slot="props"
            class="has-text-center"
          >
            <b-tag
              icon-pack="fas"
              icon="syringe"
              v-if="props.row.covid_status == 1"
              type="is-success"
              >Geimpft</b-tag
            >
            <b-tag
              icon-pack="fas"
              icon="diagnoses"
              v-if="props.row.covid_status == 2"
              type="is-success"
              >Genesen</b-tag
            >
            <b-tag
              icon-pack="fas"
              icon="syringe"
              v-if="props.row.covid_status == 3"
              type="is-warning"
              >Getestet</b-tag
            >
          </b-table-column>
          <b-table-column
            field="valid_until"
            label="Gültig bis"
            sortable
            v-slot="props"
          >
            <span v-if="props.row.status_valid_until">{{ formatDate(props.row.status_valid_until) }}</span>
          </b-table-column>
          <b-table-column
            field="updated"
            label="Letzte Aktualisierung"
            sortable
            v-slot="props"
          >
            {{ formatDateTime(props.row.updated) }}
          </b-table-column>
          <b-table-column field="favorite" label="" numeric v-slot="props">
            <b-button size="is-small" @click="showEmployeeDetails(props.row.rfid_token)"><b-icon pack="fas" icon="eye" /></b-button>
          </b-table-column>
          <template slot="bottom-left"> Gesamt: {{ totalEmployees }} </template>
        </b-table>
      </div>
      <div class="column is-3">
        <nav class="panel">
          <p class="panel-heading">Filter</p>
          <div class="panel-block">
            <p class="control has-icons-left">
              <b-input
                v-model="filter.filter_name"
                placeholder="Vorname Nachname"
                expanded
              >
              </b-input>
              <span class="icon is-left">
                <b-icon pack="fas" icon="search" />
              </span>
            </p>
          </div>
          <div class="panel-block">
            <b-button @click="loadEmployees" expanded>Suchen</b-button>
          </div>
        </nav>
        <nav class="panel">
          <p class="panel-heading">Zertifikatsart</p>
          <div class="panel-block">
            <p class="control">
              <b-select
                v-model="filter.covid_status"
                placeholder="Filtern nach Zertifikatsart..."
                @input="loadEmployees"
                expanded
              >
                <option
                  v-for="status in $store.getters.covidStatuses"
                  :value="status.id"
                  :key="status.id"
                >
                  {{ status.name }}
                </option>
              </b-select>
            </p>
          </div>
          <div class="panel-block">
            <b-button @click="resetCovidStatus" expanded>
              Zurücksetzen
            </b-button>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { dateMixin } from "../mixins/DateMixin.js";
export default {
  name: "Employees",
  components: {},
  mixins: [dateMixin],
  data() {
    return {
      data: [],
      totalEmployees: 0,
      page: 1,
      perPage: 10,
      isLoading: true,
      sort_field: 'created',
      sort_order: -1,
      filter: {
        covid_status: null,
        filter_name: null
      }      
    };
  },
  methods: {
    init() {
      this.loadEmployees();
    },
    changePage(page) {
      this.page = page;
      this.loadEmployees();
    },
    showEmployeeDetails(id){
      console.log("details", id);
      this.$router.push({ name: 'employeedetail', params: { id: id } })
    },
    sortEmployees(field, order){
      this.sort_field = field;
      this.sort_order = order == 'asc' ? 1 : -1;
      this.loadEmployees();
    },
    resetCovidStatus() {
      this.filter.covid_status = null;
      this.loadEmployees();
    },
    loadEmployees() {
      this.isLoading = true;

      const payload = {
        limit: this.perPage,
        skip: this.perPage * (this.page - 1),
        sort_field: this.sort_field,
        sort_order: this.sort_order,
        filter_certificate_type: this.filter.covid_status,
        filter_name: this.filter.filter_name
      };

      this.$store.getters.axios
        .put(this.$store.getters.apiUrl + "/employees", payload)
        .then((response) => {
          console.log(response);
          let data = response.data;

          this.data = [];
          this.data = data.tokens;
          this.totalEmployees = data.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.pagination-link.is-current {
  background-color: #c20000;
  border-color: #c20000;
  color: #fff;
}
</style>
